<template>
    <div id="searchBar" ref="searchBar" tabindex="0" class="search-bar row mx-0 mb-3">
        <Autocomplete
            :placeholder="$t('SearchBar.autocomplete.placeholder')"
            class="d-none d-lg-flex col-lg-3 search-bar__section search-bar__filter-option search-bar__autocomplete"
            :value.sync="query"
            clearable
        />

        <FilterDropdown
            :placeholder="$t('SearchBar.duration.placeholder')"
            :optionGroups="durationGroups"
            :selected.sync="durationSelected"
            class="col-12 col-sm-4 col-lg-3 search-bar__section search-bar__filter-option search-bar__duration"
        >
            <IconSvg filepath="calendar.svg" slot="prefix" />
        </FilterDropdown>

        <FilterDropdown
            :placeholder="$t('SearchBar.more.placeholder')"
            :optionGroups="otherFilterGroups"
            :selected.sync="otherSelected"
            class="col-12 col-sm-4 col-lg-3 search-bar__section search-bar__filter-option search-bar__other-option pr-sm-0"
            :class="{ 'search-bar__other-option--wide': isMapRoute }"
            show-headers
        >
            <IconSvg filepath="search/filter.svg" slot="prefix" />
        </FilterDropdown>

        <div
            class="col-12 col-sm-4 col-lg-3 search-bar__section search-bar__section-remaining pl-sm-0 justify-center"
            :class="{ 'search-bar__section-remaining--narrow': isMapRoute }"
        >
            <button
                type="button"
                @click.prevent="submitSearch(false)"
                class="btn btn-primary btn-lg search-bar__submit-button"
            >
                <IconSvg filepath="search/magnifier.svg" />
                <span class="search-bar__button-text">
                    {{ $t('SearchBar.button.search.text') }}
                </span>
            </button>

            <template v-if="!isMapRoute">
                <button
                    class="search-bar__map-hint d-none d-xl-block"
                    @click="submitSearch(true)"
                    key="searchBarButton"
                >
                    {{ $t('SearchBar.button.mapSearch.hint') }}
                </button>

                <button
                    type="button"
                    @click.prevent="submitSearch(true)"
                    class="btn btn-primary btn-lg search-bar__submit-button search-bar__map-search"
                >
                    <media-only desktop tag="span">
                        <img
                            id="mapMagnifier"
                            src="@/assets/icons/search/map-magnifier.png"
                            alt="Kartensuche"
                            class="img-fluid"
                        />
                    </media-only>
                    <media-only mobile tag="span">
                        <IconSvg filepath="search/map.svg" class="search-bar__icon-map-mobile" id="mapIconMobile" />
                    </media-only>
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import Autocomplete from '@/components/search/Autocomplete';
import FilterDropdown from '@/components/search/FilterDropdown';

export default {
    name: 'SearchBar',
    components: {
        Autocomplete,
        FilterDropdown
    },
    data() {
        const $t = this.$t;
        return {
            query: this.$route.query.q,
            term: {},
            durationSelected: [],
            durationGroups: [
                {
                    header: $t('Generic.duration'),
                    options: [
                        { text: $t('SearchBar.duration.day1'), value: 'day1' },
                        { text: $t('SearchBar.duration.day2'), value: 'day2' },
                        { text: $t('SearchBar.duration.day3'), value: 'day3' },
                        { text: $t('SearchBar.duration.day4'), value: 'day4' },
                        { text: $t('SearchBar.duration.day5'), value: 'day5' },
                        { text: $t('SearchBar.duration.day6'), value: 'day6' },
                        { text: $t('SearchBar.duration.day7_to_13'), value: 'day7_to_13' },
                        { text: $t('SearchBar.duration.day14_and_more'), value: 'day14_and_more' }
                    ]
                }
            ],
            otherFilterGroups: [
                {
                    header: $t('AdditionalHotelInfo.suitableFor'),
                    options: [
                        { text: $t('SearchBar.more.suitable_for_couples'), value: 'suitable_for_couples' },
                        { text: $t('SearchBar.more.suitable_for_singles'), value: 'suitable_for_singles' },
                        { text: $t('SearchBar.more.suitable_for_families'), value: 'suitable_for_families' },
                        { text: $t('SearchBar.more.suitable_for_dogs'), value: 'suitable_for_dogs' }
                    ]
                },
                {
                    header: $t('SearchBar.more.priceRange.header'),
                    options: [
                        { text: $t('SearchBar.more.price_range_50_99_per_person'), value: 'price_range_50_99' },
                        { text: $t('SearchBar.more.price_range_100_149_per_person'), value: 'price_range_100_149' },
                        { text: $t('SearchBar.more.price_range_150_199_per_person'), value: 'price_range_150_199' },
                        { text: $t('SearchBar.more.price_range_200_249_per_person'), value: 'price_range_200_249' },
                        { text: $t('SearchBar.more.price_range_250_299_per_person'), value: 'price_range_250_299' },
                        { text: $t('SearchBar.more.price_range_300_349_per_person'), value: 'price_range_300_349' },
                        { text: $t('SearchBar.more.price_range_350_399_per_person'), value: 'price_range_350_399' },
                        { text: $t('SearchBar.more.price_range_400_plus_per_person'), value: 'price_range_400_plus' }
                    ]
                },
                {
                    header: $t('SearchBar.more.topic.header'),
                    options: [
                        { text: $t('SearchBar.more.topic_wellness'), value: 'topic_wellness' },
                        { text: $t('SearchBar.more.topic_seaside'), value: 'topic_seaside' },
                        { text: $t('SearchBar.more.topic_activity'), value: 'topic_activity' },
                        { text: $t('SearchBar.more.topic_nature'), value: 'topic_nature' },
                        { text: $t('SearchBar.more.topic_adventure'), value: 'topic_adventure' },
                        { text: $t('SearchBar.more.topic_lakeside'), value: 'topic_lakeside' },
                        { text: $t('SearchBar.more.topic_citytrip'), value: 'topic_citytrip' },
                        { text: $t('SearchBar.more.topic_luxurytrip'), value: 'topic_luxurytrip' },
                        { text: $t('SearchBar.more.topic_mountains'), value: 'topic_mountains' },
                        { text: $t('SearchBar.more.topic_romantictrip'), value: 'topic_romantictrip' }
                    ]
                },
                {
                    header: $t('SearchBar.more.facilities.header'),
                    options: [
                        { text: $t('SearchBar.more.facilities_sauna'), value: 'facilities_sauna' },
                        { text: $t('SearchBar.more.facilities_pool'), value: 'facilities_pool' },
                        { text: $t('SearchBar.more.facilities_fitness'), value: 'facilities_fitness' },
                        { text: $t('SearchBar.more.facilities_restaurant'), value: 'facilities_restaurant' },
                        { text: $t('SearchBar.more.facilities_parking'), value: 'facilities_parking' },
                        { text: $t('SearchBar.more.facilities_publictransport'), value: 'facilities_publictransport' },
                        { text: $t('SearchBar.more.facilities_barrierfree'), value: 'facilities_barrierfree' }
                    ]
                },
                {
                    header: $t('SearchBar.more.stars.header'),
                    options: [
                        { text: $t('SearchBar.more.stars_3'), value: 'stars_3' },
                        { text: $t('SearchBar.more.stars_4'), value: 'stars_4' },
                        { text: $t('SearchBar.more.stars_5'), value: 'stars_5' }
                    ]
                }
            ],
            otherSelected: []
        };
    },
    props: {
        //filter options and query could have been grabbed from the $route object,
        //but having as a prop adds control whether to preselect
        //options and query or not
        filterOptions: Array
    },
    computed: {
        isMapRoute() {
            return this.$route.name === 'mapSearch';
        }
    },
    methods: {
        submitSearch(mapButton) {
            this.$emit('submit');

            const name = this.isMapRoute || mapButton ? 'mapSearch' : 'searchResult';
            const q = this.query;
            this.$router.push({
                name,
                query: {
                    ...this.$route.query,
                    filterOptions: [
                        ...this.durationSelected.map(duration => duration.value),
                        ...this.otherSelected.map(option => option.value)
                    ],
                    q
                },
                hash: '#searchBar'
            });
        }
    },
    watch: {
        filterOptions: {
            handler(filterOptions = []) {
                this.durationSelected = filterOptions.reduce((durationSelected, filterOption) => {
                    this.durationGroups.forEach(group => {
                        group.options.forEach(option => {
                            if (option.value === filterOption) {
                                durationSelected.push(option);
                            }
                        });
                    });
                    return durationSelected;
                }, []);

                this.otherSelected = filterOptions.reduce((otherSelected, filterOption) => {
                    this.otherFilterGroups.forEach(group => {
                        group.options.forEach(option => {
                            if (option.value === filterOption) {
                                otherSelected.push(option);
                            }
                        });
                    });
                    return otherSelected;
                }, []);
            },
            immediate: true
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;
@use '@/styles/theming' as *;

.search-bar {
    background-color: var(--body-bg);

    &__section {
        border-bottom: 1px solid var(--border-color);
    }

    &__section + &__section {
        border-left: 1px solid var(--border-color);
    }

    display: flex;
    position: relative;
    flex-wrap: nowrap;

    &:focus,
    &:active,
    &:focus-visible {
        outline: none;
    }

    &__section {
        align-items: stretch;
        min-height: 80px;
        padding: 0 variables.$grid-padding;
        display: flex;
    }

    &__section-remaining {
        align-items: stretch;
    }

    &__filter-option {
        font-size: 14px;

        .icon-svg,
        ::v-deep .icon-svg {
            font-size: 18px;
            flex: 0 0 18px;
        }

        ::v-deep .prefix {
            margin-right: 10px;
            color: variables.$muted;
        }
    }

    &__other-option {
        overflow: hidden;
    }

    &__submit-button {
        margin: 10px 0;
        margin-left: 5px;
        line-height: 1;
        flex: 2;
        max-width: 170px;
        font-size: 1.2em;
        align-self: stretch;
        white-space: nowrap;
    }

    &__map-search {
        padding: 5px;
        max-width: 110px;
        max-height: 60px;
        flex: 1;
    }

    &__map-hint {
        font-size: 12px;
        line-height: 14px;
        max-width: 80px;
        white-space: normal;
        margin-bottom: 0;
        align-self: center;
        padding-left: 5px;
        cursor: pointer;
        background: none;
        border: none;
        text-align: initial;
        outline: none;
    }

    &__icon-map-mobile {
        font-size: 30px;
    }

    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        &__duration {
            border-left: none !important;
        }
    }

    @include breakpoints.media-breakpoint-up(xl) {
        &__section-remaining {
            flex: 0 0 30%;
            max-width: 30%;

            &--narrow {
                flex: 0 0 17%;
                max-width: 17%;
            }
        }

        &__other-option {
            &--wide {
                flex: 0 0 38%;
                max-width: 38%;
            }
        }

        &__duration {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @include breakpoints.media-breakpoint-down(xs) {
        flex-wrap: wrap;

        &__section {
            border-left: none !important;
        }

        &__section-remaining {
            justify-content: center;
            border-bottom: none;
        }
    }

    @include breakpoints.media-breakpoint-only(sm) {
        &__button-text {
            display: none;
        }

        &__section-remaining {
            &--narrow {
                flex: 0 0 17%;
                max-width: 17%;
            }
        }

        &__other-option {
            &--wide {
                flex: 0 0 48%;
                max-width: 48%;
            }
        }
    }

    @include breakpoints.media-breakpoint-down(sm) {
        &__section {
            min-height: 60px;
        }

        &__submit-button {
            padding: 5px 10px;
            flex: 1;
        }

        &__filter-option ::v-deep {
            .dropdown-menu .dropdown-header {
                margin-top: 10px;
            }
        }
    }

    &__filter-option,
    &__map-hint {
        color: var(--body-color);
    }

    @include variables.ie {
        height: 80px;
    }
}
</style>
