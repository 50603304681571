/**
 * @mixin
 * Creates a hash based on some input string
 */

const hasheable = {
    methods: {
        /**
         * @method
         * @param {String} someString
         * @returns {String}
         */
        getHashKey(someString) {
            let hash = 0, i, chr;
            if (someString.length === 0) return hash;
            for (i = 0; i < someString.length; i++) {
                chr   = someString.charCodeAt(i);
                hash  = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }
            return hash;
        }
    }
};

export default hasheable;
