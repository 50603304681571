<template>
    <div class="custom-control custom-checkbox">
        <input
            type="checkbox"
            :id="id"
            :checked="checked"
            :value="value"
            @change="checkChange($event.target.checked)"
            class="custom-control-input"
        />
        <label class="custom-control-label" :for="id">
            {{ label }}
        </label>
    </div>
</template>

<script>
import hasheable from '@/mixins/hasheable';

export default {
    name: 'FillCheckBox',
    mixins: [hasheable],
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        checked: Boolean
    },
    computed: {
        id() {
            return this.getHashKey(this.label);
        }
    },
    methods: {
        checkChange(value) {
            this.$emit('update:checked', value);
        }
    }
};
</script>

<style scoped lang="scss">
.custom-checkbox {
    padding-right: 0;
    padding-left: 1.75em;

    .custom-control-input {
        & ~ .custom-control-label {
            color: var(--body-color);
            width: 100%;

            &::before,
            &::after {
                width: 1.2em;
                height: 1.2em;
                top: 0.2em;
                left: -1.7em;
            }

            &::before {
                border-radius: 0;
                outline: 1px solid var(--border-color);
                border: 2px solid hsl(0, 0%, 100%);
            }
        }

        &:checked {
            & ~ .custom-control-label {
                &::after {
                    background-image: none; //cleaning up the bootstrap provided svg
                }
            }
        }
    }
}
</style>
