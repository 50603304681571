<template>
    <div class="filter-dropdown" :class="{ 'wide-menu': wideMenu }" ref="dropdown">
        <div class="dropdown-toggle" :class="{ active: selected.length }" @click="toggle">
            <span class="prefix">
                <slot name="prefix"></slot>
            </span>

            <slot name="text">
                <span class="filter-text text-truncate" :title="filterText !== placeholder && filterText">
                    {{ filterText }}
                </span>
            </slot>

            <IconSvg filepath="arrow-down.svg" :class="{ active: opened }" />
        </div>

        <slot name="menu">
            <Dismissible :show.sync="opened">
                <template v-if="opened">
                    <ul v-if="!wideMenu" class="dropdown-menu">
                        <li v-if="showHeaders">
                            <h4 class="dropdown-header">{{ optionGroups[0].header }}</h4>
                        </li>
                        <li
                            v-for="(option, index) in optionGroups[0].options"
                            :key="'option' + index"
                            class="dropdown-item"
                        >
                            <FillCheckBox
                                :label="option.text"
                                :value="option.value"
                                :checked="selected.some(sel => sel.value === option.value)"
                                @update:checked="updateChecked($event, option)"
                            />
                        </li>
                        <li class="text-right px-3">
                            <button
                                type="button"
                                class="btn btn-light btn-sm"
                                :disabled="!selected.length"
                                @click.prevent="clearFilter"
                            >
                                {{ $t('SearchBar.dropdown.button.reset') }}
                            </button>
                        </li>
                    </ul>

                    <div v-else class="dropdown-menu container">
                        <div class="row justify-content-md-center">
                            <div
                                v-for="(group, index) in optionGroups"
                                :key="'filterGroup' + index"
                                class="col-12 col-sm-6 col-md-4 col-lg-2"
                            >
                                <h4 v-if="showHeaders" class="dropdown-header">{{ group.header }}</h4>

                                <FillCheckBox
                                    v-for="(option, index) in group.options"
                                    :key="group.header + 'option' + index"
                                    :label="option.text"
                                    :value="option.value"
                                    :checked="selected.some(sel => sel.value === option.value)"
                                    @update:checked="updateChecked($event, option)"
                                    class="dropdown-item"
                                >
                                </FillCheckBox>
                            </div>
                            <div class="w-100"></div>
                            <div class="col-12 text-right">
                                <button
                                    type="button"
                                    class="btn btn-light btn-sm"
                                    :disabled="!selected.length"
                                    @click.prevent="clearFilter"
                                >
                                    {{ $t('SearchBar.dropdown.button.reset') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </Dismissible>
        </slot>
    </div>
</template>

<script>
import FillCheckBox from '@/components/search/FillCheckBox';
import Dismissible from '@/components/Dismissible';

export default {
    name: 'FilterDropdown',
    components: {
        FillCheckBox,
        Dismissible
    },
    data() {
        return {
            opened: false
        };
    },
    props: {
        optionGroups: Array,
        placeholder: {
            type: String,
            required: true
        },
        showHeaders: Boolean,
        selected: Array
    },
    computed: {
        filterText() {
            return this.selected.map(item => item.text).join(', ') || this.placeholder;
        },
        wideMenu() {
            return this.optionGroups.length > 1;
        }
    },
    methods: {
        toggle() {
            this.opened = !this.opened;
        },
        clearFilter() {
            this.$emit('update:selected', []);
        },
        updateChecked(value, item) {
            const selected = this.selected;
            if (value) {
                this.$emit('update:selected', [...selected, item]);
            } else {
                const index = selected.findIndex(sel => sel.value === item.value);
                this.$emit('update:selected', [
                    ...selected.slice(0, index),
                    ...selected.slice(index + 1, selected.length)
                ]);
            }
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;

.filter-dropdown {
    position: relative;
    display: flex;

    &.wide-menu {
        overflow: visible;

        @include breakpoints.media-breakpoint-up(sm) {
            position: static; //to prevent dropdown-menu width from being dependent on the parent element dimensions
        }
    }

    .dropdown-toggle {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        width: 100%;

        .prefix {
            margin-right: 10px;
        }

        .filter-text {
            flex: 1;
        }

        &::after {
            content: none;
        }

        .icon-arrow-down {
            &.active {
                transform: rotate(180deg);
            }
        }

        &.active,
        &:hover {
            .prefix,
            .icon-arrow-down {
                color: var(--primary) !important;
            }
        }
    }

    .dropdown-menu {
        border: 1px solid var(--border-color);
        display: block;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        background: hsl(0, 0%, 100%);
        list-style: none;
        padding: 10px 0;
        outline: none;
        cursor: default;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        font-size: inherit;

        &.container {
            padding-left: variables.$grid-padding;
            padding-right: variables.$grid-padding;
        }

        .dropdown-item:active,
        .dropdown-item.active {
            background-color: inherit;
        }

        .dropdown-header {
            padding: 0;
            text-transform: uppercase;
            margin-bottom: 3px;
        }

        li.dropdown-item {
            padding: 3px 10px;
        }

        @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
            max-height: 60vh;
            overflow-y: auto;
        }
    }
}
</style>
